/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;

.footer {
  width: 100%;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  // Makes teh footer stick to teh bottom of the page
  margin-top: auto;

  & > div {
    margin: 0px auto;
    max-width: $max-page-width;

    ul {
      display: flex;
      width: 100%;
      height: 4rem;
      flex-direction: row-reverse;

      li {
        padding: 0;
        margin: 0;
        list-style: none;

        a {
          color: $black;
          height: 4rem;
          display: flex;
          align-items: center;
          align-content: center;
          padding: 0rem 1rem;
          font-size: 0.7rem;
          font-weight: bold;
          transition: 0.25s;
          &:hover {
            background: $gray;
          }
        }
      }
    }
  }
}
