/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

.header {
  padding: 1rem 2rem;
  padding-bottom: 0;
  display: flex;
  & > div {
    display: flex;
    width: 100%;
    margin: 0px auto;
    align-items: center;
    max-width: $max-page-width;
    font-weight: 400;
    a {
      border-bottom: solid 1px $primary;
    }
  }
}

.body {
  padding: 1rem 2rem 4rem 1rem;
  overflow-x: auto;
  & > div {
    margin: 0px auto;
    max-width: $max-page-width;
  }
}
